<template>
    <div>
      <v-layout wrap py-10 justify-center>
        <v-flex xs12>
          <span
            style="color: #262626; font-family: poppinsregular; font-size: 20px"
            >You may also like</span
          >
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs11 sm12>
          <v-layout wrap justify-start>
            <v-flex xs12 sm6 md3 pa-3 v-for="(list, l) in relatedProducts" :key="l">
              <v-card outlined height="400px">
                <router-link :to="'/productDetails?productName=' + list.productId.productname + '&id=' + list.productId._id">
                <v-layout wrap justify-center py-5>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs6 sm6 text-left v-if="list.offerPercentage>1">
                        <v-img
                          height="30px"
                          width="70px"
                          src="./../../assets/icons/tag.png"
                        >
                          <span
                            style="
                              color: #ffffff;
                              font-size: 13px;
                              font-family: opensanssemibold;
                            "
                          >
                            &nbsp; {{list.offerPercentage.toFixed()}} %</span
                          >
                        </v-img>
                        <!-- <span style="color: #FFFFFF; font-size: 13px; font-family: opensanssemibold;">- 25%</span> -->
                      </v-flex>
                      <v-flex xs6 sm6 pl-15>
                        <!-- <v-icon>mdi-heart-outline</v-icon> -->
                      </v-flex>
                      <v-flex xs12 v-if="list">
                        <v-img contain height="150px" :src="mediaUURL + list.productId.photos[0]"></v-img>
                      </v-flex>
                      <v-flex xs12 v-if=" list.category">
                        <span
                          style="
                            color: #bcb4b4;
                            font-family: poppinsmedium;
                            font-size: 12px;
                          "
                          >{{ list.category.name}}</span
                        >
                      </v-flex>
                      <v-flex xs12 px-2>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 16px;
                            text-transform: uppercase;
                          "
                          >{{ list.productId.productname }}</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 14px;
                          "
                          >{{ list.size }}</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <span
                          style="
                            color: #30b868;
                            font-family: RobotoMedium;
                            font-size: 15px;
                          "
                          >₹ {{ list.offerPrice }} &nbsp;</span
                        >
                        <span v-if="list.offerPrice!= list.price"
                          style="
                            color: #8b8b8b;
                            font-family: RobotoMedium;
                            font-size: 13px;
                            text-decoration: line-through;
                          "
                        >
                        ₹ {{ list.price }}</span
                        >
                      </v-flex>
                      <v-flex xs6 sm6 text-center pt-3>
                        <v-btn color="#30B868" dark block :to="'/productDetails?productName=' + list.productId.productname + '&id=' + list.productId._id">
                          <span
                            style="
                              font-family: opensanssemibold;
                              text-transform: none;
                              font-size: 14px;
                            "
                            >Buy Now</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </router-link>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  export default {
    props:["relatedProducts"],
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
      };
    },
  };
  </script>