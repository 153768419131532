<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex xs12 pl-xs-0 pl-sm-0 pl-md-8>
        <v-card>
          <v-layout wrap justify-center pt-5 pb-4 px-5>
            <v-flex xs12 text-left>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 15px;
                  color: #000000;
                "
              >
                Payment Summary
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center px-5 pt-3 pb-5>
            <v-flex xs8 v-if="page == 'cart' && !promocode">
              <v-text-field
                placeholder="CODE"
                solo
                flat
                v-model="code"
                background-color="#F5F5F5"
                dense
                color="#F5F5F5"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 text-right v-if="page == 'cart' && !promocode">
              <v-btn color="#E96125" dark depressed @click="applyPromo()">
                <span style="font-family: poppinsregular; text-transform: none"
                  >Apply</span
                >
              </v-btn>
            </v-flex>
            <v-flex xs6 text-left>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                Price
              </span>
            </v-flex>
            <v-flex xs6 text-right>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                ₹ {{ summary.totalAmount }}
              </span>
            </v-flex>
            <v-flex xs6 pt-3 text-left>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                Additional charges
              </span>
            </v-flex>
            <v-flex xs6 pt-3 text-right>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                ₹ {{ summary.convenienceCharge }}
              </span>
            </v-flex>
            <v-flex xs6 pt-3 text-left>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                Delivery charges
              </span>
            </v-flex>
            <v-flex xs6 pt-3 text-right>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 14px;
                  color: #000000;
                "
              >
                ₹ {{ summary.deliveryCharge }}
              </span>
            </v-flex>
            <v-flex xs12 v-if="promocode">
              <v-layout wrap>
                <v-flex xs6 sm8 pt-3 text-left>
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      color: #000000;
                    "
                  >
                    Coupon discount ({{ promocode.promoCodeId.code }})
                  </span>
                  <br /><span
                    style="
                      font-family: poppinsregular;
                      color: red;
                      font-size: 13px;
                      cursor: pointer;
                    "
                    @click="removePromocode(promocode)"
                    >Remove</span
                  >
                </v-flex>
                <v-flex xs6 sm4 pt-3 text-right>
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      color: #000000;
                    "
                  >
                    - ₹ {{ summary.discount }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex xs12 pt-3 v-if="promocode">
              <v-layout wrap>
                <v-flex xs12 class="dashed-line">
                </v-flex>
              </v-layout>
            </v-flex> -->
            <v-flex xs12 pt-3>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs6 pt-3 text-left>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      color: #000000;
                    "
                  >
                    Total Amount
                  </span>
                </v-flex>
                <v-flex xs6 pt-3 text-right>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      color: #000000;
                    "
                  >
                    ₹ {{ summary.subTotal }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-if="page == 'cart'">
              <v-layout wrap justify-end>
                <v-flex xs12 sm10 pt-4 text-right>
                  <span style="font-family: RobotoMedium; font-size: 13px"
                    >Tax included. Shipping and discounts calculated at
                    checkout.</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 pt-5 v-if="page == 'cart'">
              <v-btn block color="#E96125" dark depressed @click="checkLogin()"
                ><span
                  style="font-family: poppinsregular; text-transform: none"
                >
                  Continue</span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 pt-5 v-if="page == 'address'">
              <v-btn
                block
                color="#E96125"
                dark
                depressed
                @click="handleAddress()"
                ><span
                  style="font-family: poppinsregular; text-transform: none"
                >
                  Proceed</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs11 py-5>
        <v-layout wrap justify-center>
          <!-- <v-flex xs1><v-icon>mdi-shield-check</v-icon></v-flex>
          <v-flex xs11> -->

          <span
            style="color: grey; font-family: poppinssemibold; font-size: 15px"
          >
            <v-icon>mdi-shield-check</v-icon> Safe and Secure Payments.Easy
            returns.100% Authentic products.</span
          >
          <!-- </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["storage", "page", "address", "summary", "promocode"],
  components: {
    //   PaymentMethod,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      deliverydate: new Date(),
      user: {
        deliveryaddress: [],
      },
      checkbox: false,
      dialog: false,
      cart: {},
      paymentMethodDialog: false,
      promoCode: null,
      code: "",
      coupenData: {},
      isCODAvailable: false,
      link: "",
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
  },
  methods: {
    checkLogin() {
      if (!this.appLogin) {
        this.$router.push("/loginForm");
      } else this.$router.push("/address");
    },
    handleAddress() {
      if (!this.appLogin) {
        this.$router.push("/loginForm");
      } else if (!this.address) {
        this.msg = "Please Select / Add Address";
        this.showSnackBar = true;
      } else {
        this.checkOut();
      }
    },
    applyPromo() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/redeeme/promoCode",
        method: "POST",
        data: {
          code: this.code,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.promoCode = response.data.data;
            this.$emit("stepper", {
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removePromocode(item) {
      var promo = {};
      promo["id"] = item._id;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        method: "POST",
        url: "/promoCode/remove",
        data: promo,
        headers: headers1,
      })
        .then((response) => {
          if (response.data.status) {
            location.reload();
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkOut() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/checkOut",
        data: {
          deliveryAddressId: this.address._id,
          totalAmount: this.summary.subTotal,
          promocodeDiscount: this.summary.discount,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.$router.push("/cart");
            document.open();
            document.write(response.data.data);
            document.close();
            // window.id = response.data.paymentdata._id;
            // window.orderid = response.data.data.order_id;
            // this.link = response.data.url;
            // window.location.href = this.link;
            //  this.msg = response.data.msg;
            //   this.snackbar = true;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
<style scoped>
.dashed-line {
  border: 1px dashed #000; /* You can adjust the width and color as needed */
  padding: 0px;
}
</style>
  