<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-10>
      <v-flex xs12 lg10 xl10 px-0 px-sm-4 pt-4 px-2 pr-lg-12 pr-xl-0>
        <v-layout wrap justify-center pt-16>
          <v-flex
            xs12
            sm12
            lg6
            xl6
            text-center
            text-uppercase
            style="font-family: poppinssemibold; font-size: 15px"
          >
            <span style="color: #30b868"> Cart </span>
            <span class="pl-4" style="letter-spacing: 10px; color: #a39f9f">
              &bullet;&bullet;&bullet;&bullet;&bullet;
            </span>
            <!-- <router-link to="/address"> -->
              <span class="pl-4" style="color: #a39f9f"> ADDRESS </span>
            <!-- </router-link> -->
            <span class="pl-4" style="letter-spacing: 10px; color: #a39f9f">
              &bullet;&bullet;&bullet;&bullet;&bullet;
            </span>
            <span class="pl-4" style="color: #a39f9f"> PAYMENT </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start pt-5>
          <v-flex
            xs12
            sm3
            md3
            lg3
            xl3
            text-left
            text-uppercase
            style="font-family: poppinsmedium; font-size: 14px; color: #3c3c3c"
          >
            <span> Products </span>
          </v-flex>
          <v-flex
            xs12
            text-left
            v-if="sellerInfo"
            style="font-family: poppinsmedium; font-size: 13px; color: black"
          >
            <span> {{ sellerInfo.shopName }} </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="cart.length > 0">
          <v-flex xs12 sm12 md8 lg8 xl8 pt-4>
            <v-layout wrap justify-start>
              <v-card>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm12
                    pa-2
                    v-for="(item, i) in cart"
                    :key="i"
                    text-left
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <CartItem
                          v-bind:storage="item"
                          :list="list"
                          v-bind:page="'cart'"
                          @stepper="winStepper"
                        />
                      </v-flex>
                      <v-flex xs12 py-4 v-if="i < cart.length - 1">
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 xl4 pt-4>
            <OrderDetails v-bind:page="'cart'" :summary="summary" :promocode="promocode"
            @stepper="winStepper"
            />
          </v-flex>
          <!-- v-bind:storage="cart"
                v-bind:page="'cart'"
                :key="cart.total"
               @stepper="winStepper" -->
        </v-layout>
        <v-layout wrap justify-center pt-5 v-else>
          <v-flex xs12 pa-2 align-self-center>
            <span
              style="
                font-family: poppinsregular;
                font-size: 20px;
                color: #000000;
                cursor: pointer;
              "
              >Oops! No Products Found</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-10  v-if="relatedProducts.length>0">
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 sm10 v-if="relatedProducts.length>0">
        <SimilarProducts :relatedProducts="relatedProducts" />
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import CartItem from "./cartItem";
import OrderDetails from "./orderDetails";
import SimilarProducts from "./../EcoShop/similarProductsCart";
export default {
  components: {
    CartItem,
    OrderDetails,
    SimilarProducts,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      cart: [],
      summary: {},
      searchKey: null,
      pincode: null,
      list: [],
      relatedProducts:[],
      sellerInfo:"",
      promocode:"",
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  beforeMount() {
    this.getCart();
    this.getProducts()
  },
  methods: {
    winStepper(window_data) {
      if (window_data.getData) {
        this.getCart();
      }
    },
    getCart() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart",
        method: "GET",
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.cart = response.data.items;
          this.summary = response.data.summary;
          if(response.data.promocode.length>0){
          this.promocode=response.data.promocode[0]
          console.log("haii",this.promocode)
          }
          this.sellerInfo=response.data.sellerInfo;
          this.$store.commit("changeCart", response.data.totalLength);
          for (var i = 0; i <= this.cart.length; i++) {
            for (var l = 1; l <= this.cart[i].itemId.stock[0].stock; l++) {
              this.list.push(l);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    getProducts() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart/similarProducts",
        method: "GET",
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.relatedProducts=response.data.data
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  