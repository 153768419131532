<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center px-2 py-2>
      <v-flex xs3 sm2>
        <router-link
          :to="
            '/productDetails?productName=' +
            storage.productId.productname +
            '&id=' +
            storage.productId._id
          "
        >
          <v-img
            :src="mediaUURL + storage.productId.photos[0]"
            height="130px"
            width="100%"
            contain
            style="border-radius: 30px"
            :alt="storage.productId.productname"
          >
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img>
        </router-link>
      </v-flex>
      <v-flex xs10>
        <v-layout wrap justify-center fill-height>
          <v-flex xs12 sm12 md12 align-self-center text-left>
            <v-layout wrap justify-start style="line-height: 16px" fill-height>
              <v-flex xs12>
                <router-link
                  :to="
                    '/productDetails?productName=' +
                    storage.productId.productname +
                    '&id=' +
                    storage.productId._id
                  "
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 15px;
                      color: #191919;
                    "
                  >
                    {{ storage.productId.productname }}
                    <span v-if="storage.itemId.size"
                      >({{ storage.itemId.size }})</span
                    >
                    <!-- <span v-if="storage.productId.productname.length > 25">
                      ...
                    </span> -->
                  </span>
                </router-link>
              </v-flex>
              <!-- <v-flex xs12 pt-3>
                <span
                  style="
                    color: #191919;
                    font-size: 13px;
                    font-family: poppinsmedium;
                  "
                  >Size
                  <span style="font-family: poppinsregular"
                    >: {{ storage.itemId.size }}</span
                  >
                </span>
              </v-flex> -->
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 md5 lg6 xl7>
            <v-layout wrap ustify-start>
              <v-flex xs12 pt-1>
                <span
                  style="
                    color: #191919;
                    font-size: 13px;
                    font-family: poppinsmedium;
                  "
                  >Category
                  <span style="font-family: poppinsregular"
                    >: {{ storage.category.name }}
                  </span>
                </span>
              </v-flex>
              <v-flex xs12 pt-1>
                <span
                  style="
                    color: #191919;
                    font-size: 13px;
                    font-family: poppinsmedium;
                  "
                  >Price
                  <span style="font-family: poppinsregular"
                    >: {{ storage.quantity }} x ₹
                    {{ storage.offerPricePerItem }}
                  </span>
                </span>
              </v-flex>
              <v-flex xs4 sm5 md4 lg3 xl2 pt-2>
                <v-card outlined>
                  <v-select
                    dense
                    v-model="storage.quantity"
                    :items="list"
                    color="grey"
                    item-text="name"
                    @change="cartChange(storage)"
                    item-value="value"
                    :hide-details="true"
                    height="25px"
                    class="no-border pl-4"
                    variant="underlined"
                  >
                  </v-select>
                </v-card>
              </v-flex>
              <v-flex xs4 sm3 pa-2 v-if="storage.itemId.size">
                <v-btn
                  small
                  light
                  depressed
                  outlined
                  height="30px"
                  :ripple="false"
                  color="#090909"
                  class="sizebutton px-2"
                >
                  <span style="font-family: poppinssemibold">{{
                    storage.itemId.size
                  }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm8 md7 lg6 xl5 text-left>
            <v-layout wrap justify-end pt-3>
              <v-flex xs12 text-center>
                <span
                  style="
                    color: #30b868;
                    font-family: poppinsmedium;
                    font-size: 24px;
                  "
                >
                  ₹ {{ storage.total }}</span
                >
              </v-flex>
              <v-layout wrap pt-7 justify-end>
                <v-flex xs8 sm7 md7 text-left v-if="page == 'cart'">
                  <v-btn
                    color="#E96125"
                    dark
                    depressed
                    @click="addWishlist(storage)"
                    ><span
                      style="
                        font-family: opensansregular;
                        text-transform: none;
                        font-size: 13px;
                      "
                      >Move to Wishlist</span
                    ></v-btn
                  >
                </v-flex>
                <v-flex
                  xs4
                  sm4
                  md4
                  lg4
                  text-left
                  v-if="page == 'cart'"
                  style="cursor: pointer"
                >
                  <v-dialog v-model="storage.dialogDelete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="red" dark depressed v-on="on" v-bind="attrs"
                        ><span
                          style="
                            font-family: opensansregular;
                            text-transform: none;
                            font-size: 13px;
                          "
                          >Remove</span
                        ></v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        style="
                          font-family: poppinsregular;
                          font-size: 15px;
                          color: #000;
                        "
                        >Are you sure you want to remove this product from
                        cart?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          small
                          @click="storage.dialogDelete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          small
                          @click="removeFromCart(storage)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs1 text-right>
        <v-icon
          color="#888888"
          small
          style="cursor: pointer"
          @click="removeFromCart(storage)"
          >mdi-close</v-icon
        >
      </v-flex> -->
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["storage", "list", "page"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      quantity: "",
      productSize: {},
      items: ["1", "2", "3", "4"],
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  methods: {
    cartChange(item) {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart/add",
        method: "POST",
        data: {
          itemId: item.itemId._id,
          // guestid:this.guestid
          quantity: item.quantity,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cart = response.data.data;
            this.$emit("stepper", {
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeFromCart(item) {
      var cart = {};
      cart["id"] = item._id;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        method: "POST",
        url: "/cart/itemRemove",
        data: cart,
        headers: headers1,
      })
        .then((response) => {
          if (response.data.status) {
            this.dialogDelete = false;
            location.reload();
            this.$store.commit("changeCart", response.data.totalLength);
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addWishlist(item) {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/wishList/add",
        method: "POST",
        data: {
          id: item.itemId._id,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.removeFromCart(item);
            this.$store.commit("changeWishlist", response.data.wishlistcount);
            this.$router.push("/wishlist");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
/* Add this style to remove the underline */
.theme--light.v-text-field.no-border
  > .v-input__control
  > .v-input__slot::before {
  border-width: 0;
}
</style>